<template>
  <div class="card">
    <div class="card-header">
      <ul class="nav nav-pills" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link active"
            data-toggle="pill"
            href="#transaction"
            role="tab"
            aria-selected="true"
            >Initiate Transaction</a
          >
        </li>
      </ul>
    </div>

    <div class="card-body market-limit">
      <div class="d-flex justify-content-between my-3 flex-wrap">
        <div class="col-12 col-md-6 my-2 pl-0">
          <span>Available Balance | <small>expires {{ expiryDate }}</small></span>
            <h1 class="text-primary">${{ balance }}</h1>
        </div>
        <div class="col-12 col-md-6 my-2 pl-0 pl-md-3">
            <p class="mb-1">Current BTC Price</p>
              <h4>{{ rateValue }} </h4>
        </div>
      </div>
      <div class="tab-content">
        <div class="tab-pane fade show active" id="limit" role="tabpanel">
          <form
            method="post"
            name="myform"
            class="currency_limit"
            @submit.prevent="addTrade"
          >
            <div class="form-group row align-items-center">
              <div class="col-sm-4">
                <label for="inputEmail3" class="col-form-label">
                  Select Wallet
                </label>
              </div>
              <div class="col-sm-8">
                <div class="input-group mb-3 flex-wrap d-flex">
                  <select
                    name="wallet"
                    class="form-control mw-150 col-12"
                    v-model="wallet"
                  >
                    <option value="" disabled selected>Select Wallet</option>
                    <option
                      v-for="wallet in wallets"
                      :key="wallet.id"
                      :value="wallet.address"
                    >
                      {{ wallet.description }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div v-show="wallet" class="form-group row align-items-center">
              <div class="col-sm-4">
                <label for="inputEmail3" class="col-form-label"
                  >Wallet Address
                  <br />
                  <small>Please double check this address</small>
                </label>
              </div>
              <div class="col-sm-8">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <label class="input-group-text bg-primary"
                      ><i class="mdi mdi-currency-btc fs-18 text-white"></i
                    ></label>
                  </div>
                  <input
                    type="text"
                    readonly
                    class="form-control text-right"
                    placeholder="3E8ociqZa9mZUSwGdSmAEMAoAxBK3FNDcd"
                    :value="wallet"
                  />
                </div>
              </div>
            </div>

            <div class="form-group row align-items-center">
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label for="amount">Amount In USD</label>
                  <input
                    id="amount"
                    type="number"
                    name="usd_amount"
                    class="form-control text-right col-12"
                    placeholder=""
                    v-model.number="amount_fiat"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label for="amount">Amount In Crypto</label>
                  <input
                    id="amount"
                    type="text"
                    name="usd_amount"
                    class="form-control text-right col-12"
                    placeholder=""
                    v-model="amount_crypto"
                  />
                </div>
              </div>
            </div>

            <div class="text-right">
              <button
                class="btn btn-primary"
                @click.prevent="addTrade"
                :disabled="!wallet || !amount_fiat"
              >
                Submit Bid
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import client from "@/api/client";

export default {
  name: 'NewTrade',
  data: () => ({
    balance: 0,
    deposits: [],
    wallets: [],
    accounts: [],
    wallet: "",
    trade: {
      amount_fiat: "",
      amount_crypto: "",
    },
    amount_fiat: null,
    amount_crypto: null,
    rate: '',
    rateValue: '',
  }),
  computed: {
    expiryDate() {
      if (this.deposits.length > 0) {
        const lastDeposit = [...this.deposits].sort((a, b) => b.id - a.id)[0];
        const date = new Date(lastDeposit.created_at);
        date.setHours(48);
        return date.toDateString();
      }
      return null;
    }
  },
  watch: {
    amount_crypto(val) {
      if (this.rate) {
        this.trade.amount_fiat = parseFloat(val * this.rate.ask);
        this.amount_fiat = parseFloat(val * this.rate.ask);
      }
    },
    amount_fiat(val) {
      if (this.rate) {
        this.trade.amount_crypto = parseFloat(val / this.rate.ask);
        this.amount_crypto = parseFloat(val / this.rate.ask);
      }
    },
  },
  mounted() {
    this.getBalance();
    this.getWallets();
    api.rate().then((res) => {
      this.rate = res;
      this.rateValueFunc();
    });
  },
  methods: {
    getBalance() {
      api.balance().then((res) => {
        this.balance = res;
      });
    },
    getWallets() {
      api.wallet.read().then((res) => {
        this.wallets = res;
      });
    },
    addTrade() {
      this.$toast.clear();
      this.trade.wallet_id = this.wallets.find(
        (el) => el.address === this.wallet
      ).id;
      api.trade
        .create(this.trade)
        .then((res) => {
          this.$toast.success("Bid successfully submitted");
          this.wallet = "";
          this.trade.amount_fiat = null;
          this.getBalance();
          this.$emit('created');
        })
        .catch((err) => {
          this.$toast.error(err);
        });
    },
    rateValueFunc(){
      this.rateValue = this.rate.ask.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2, style: 'currency', currency: 'USD' })
    },
  },

};
</script>

<style>
</style>