<template>
  <div>
    <div class="container-fluid">
      <Terms :show-modal="showModal" @hide="showModal = false" />
      <div class="row">
        <div v-if="!accounts.length || !wallets.length || !approvedDocuments" class="col-xl-6">
          <div class="alert">
            <span
              class="closebtn d-none"
              onclick="this.parentElement.style.display='none';"
              >&times;</span
            >
            <ul>
              <li v-if="accounts.length < 1">
                <p>We need the following information from you before you can complete a trade</p>
                <i class="fa fa-users"></i> Add your
                <router-link to="verify-step-5">bank account here</router-link>
              </li>
              <li v-if="wallets.length < 1">
                <i class="fa fa-bank"></i> Add your
                <router-link to="add-crypto-address">crypto wallet here</router-link>
              </li>
              
              <li v-if="!approvedDocuments && !pendingDocuments">
                <i class="fa fa-lock"></i> Fill your
                <router-link to="settings">VIP Application Form</router-link>
              </li>
              <li v-if="pendingDocuments">
                <i class="fa fa-lock"></i> Your VIP Application is currently pending account review
              </li>
            </ul>
          </div>
        </div>

        <div class="col-xl-6">
          <div class="card acc_balance">
            <div class="card-header">
              <h4 class="card-title">Account Overview</h4>
            </div>
            <div class="card-body">
              <span>Available Balance <small v-if="balance > 0">| expires {{ expiryDate }}</small></span>
              <h1 class="text-primary">${{ balance }}</h1>

              <div class="d-flex justify-content-between my-3 flex-wrap">
                <div class="col-12 col-md-6 my-2 pl-0">
                  <p class="mb-1">Last Deposit Amount</p>
                  <h4>$ {{ fiatBuy }}</h4>
                </div>
                <div class="col-12 col-md-6 my-2 pl-0 pl-md-3">
                  <p class="mb-1">Total ₿ buy this month</p>
                  <h4>{{ totalCrypto }} BTC</h4>
                </div>
              </div>

              <!-- <div class="d-flex justify-content-between my-3 flex-wrap">
                <div class="col-12 col-md-6 my-2 pl-0">
                  <p class="mb-1">Total Fiat buy all time</p>
                  <h4>$56845.25</h4>
                </div>
                <div class="col-12 col-md-6 my-2 pl-0 pl-md-3">
                  <p class="mb-1">Total ₿ buy all time</p>
                  <h4>3.0215485 BTC</h4>
                </div>
              </div> -->
            </div>
          </div>
        </div>

        
        <div class="col-xl-6 col-xxl-12 col-lg-12 col-xxl-6">
          <div class="card acc_balance">
            <div class="card-header">
              <h4 class="card-title">Deposits</h4>
            </div>
            <div class="card-body">
              <div v-if="loading" class="card__loader"></div>
              <div class="table-responsive">
                <template v-if="pendingDeposits.length < 1">
                  <div>
                    <span>Available Balance <small v-if="deposits.length && balance > 0">| expires {{ expiryDate }}</small></span> 
                    <h1 class="text-primary">${{ balance }}</h1>
                    <div class="row justify-content-center my-4">
                      <a href="verify"
                        ><button class="btn btn-primary">DEPOSIT</button></a
                      >
                    </div>
                  </div>
                </template>
                <table v-else class="table linked_account">
                  <thead class="my-3">
                    <tr>
                      <th>Pending Amount</th>
                      <th>Date Inititated</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="deposit in pendingDeposits"
                      :key="deposit.id"
                    >
                      <td>
                        <div class="media">
                          <span class="mr-3"
                            ><i class="fa fa-credit-card"></i
                          ></span>
                          <div class="media-body">
                            <h5 class="mt-0 mb-1">$ {{ deposit.amount_fiat }}</h5>
                            <p>{{ getAccountNumber(deposit.account_id) }}</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p>{{ new Date(deposit.created_at).toDateString() }}</p>
                      </td>
                      <td>
                        <div class="media-body">
                        <p>Pending</p>
                        </div>
                      </td>
                      <td>
                        <div class="edit-option">
                          <a href="javascript:void(0)" @click.prevent="deleteDeposit(deposit)"><i class="fa fa-trash"></i></a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-6 col-xxl-12 col-lg-12 col-xxl-6">
          <NewTrade />
        </div>
       
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api';
import client from '@/api/client'
import Terms from '@/components/Terms.vue';
import NewTrade from '@/components/NewTrade.vue';

export default {
  components: { Terms, NewTrade },
  data: () => ({
    approvedDocuments: false,
    pendingDocuments: false,
    balance: 0,
    deposits: [],
    wallets: [],
    accounts: [],
    wallet: '',
    trade: {
      amount_fiat: '',
      amount_crypto: '',
    },
    trades: [],
    showModal: false,
    loading: false,
  }),
  computed: {
    hideCard() {
      return !this.accounts.length || !this.wallets.length;
    },
    expiryDate() {
      if (this.deposits && this.deposits.length > 0) {
        const lastDeposit = [...this.deposits].sort((a, b) => b.id - a.id)[0];
        const date = new Date(lastDeposit.created_at);
        date.setHours(48);
        return date.toDateString();
      }
      return new Date().toDateString();
    },
    fiatBuy() {
      if (this.deposits && this.deposits.length > 0) {
        const fiatDeposits = this.deposits.filter((el) => el.currency_fiat.toLowerCase() === 'usd');
        return fiatDeposits[fiatDeposits.length - 1].amount_fiat;
      }
      return 0;
    },
    btcBuy() {
      if (this.deposits && this.deposits.length > 0) {
        const btcDeposits = this.deposits.filter((el) => el.currency_fiat.toLowerCase() !== 'usd');
        return btcDeposits[btcDeposits.length - 1].amount_fiat;
      }
      return 0;
    },
    pendingDeposits() {
      return this.deposits.filter((deposit) => !deposit.verified_at && !deposit.canceled_at && !deposit.rejected_at) || [];
    },
    totalCrypto() {
      if (this.trades.length) {
        const total = this.trades.reduce((acc, el) => acc + parseFloat(el.amount_crypto), 0);
        return parseFloat(total).toFixed(5);
      }
      return 0;
    },
  },
  mounted() {
    this.getBalance();
    this.getDeposits();
    this.getWallets();
    this.getAccounts();
    this.getTrades();
    const hideTerms = localStorage.getItem('hideTerms');
    if (hideTerms === false || !hideTerms) {
      this.showModal = true;
    }

    api.user.getApprovedInfo().then(data => {
      if(data.length > 0){
        this.approvedDocuments = true;
      }
    }, err => {
     this.$toast.error('Something went wrong, please refresh');
    })

    api.user.getPendingInfo().then(data => {
      if (data.length > 0) {
        this.pendingDocuments = true;
      }
    }, err => {
     this.$toast.error('Something went wrong, please refresh');
    })

  },
  methods: {
    getTrades() {
      api.trade.read().then((res) => {
        this.trades = res || [];
      });
    },
    getBalance() {
      api.balance().then((res) => {
        this.balance = res || 0;
      });
    },
    getDeposits() {
      this.loading = true;
      api.deposit.read().then((res) => {
        this.loading = false;
        this.deposits = res || [];
      });
    },
    getWallets() {
      api.wallet.read().then((res) => {
        this.wallets = res || [];
      });
    },
    getAccounts() {
      api.account.read().then((res) => {
        this.accounts = res || [];
      });
    },
    getAccountNumber(id) {
      const account = this.accounts.find((el) => el.id === id)
      if (account) {
        return account.account_number;
      }
    },
    deleteDeposit(deposit) {
      this.$toast.clear();
      const canDelete = confirm('Are you sure you want to delete this deposit');
      if (canDelete) {
        this.loading = true;
        api.deposit.delete(deposit.id).then((res) => {
          this.loading = false;
          this.$toast.success('Deposit deleted successfully');
          this.getDeposits();
        }).catch((err) => {
          this.loading = false;
          this.$toast.error(err);
        });
      }
    },
    addTrade() {
      this.$toast.clear();
      this.trade.wallet_id = this.wallets.find((el) => el.address === this.wallet).id;
      api.trade.create(this.trade).then((res) => {
        this.$toast.success('Bid successfully submitted');
        this.wallet = "";
        this.trade.amount_fiat = null;
      }).catch((err) => {
        this.$toast.error(err);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  padding: 20px;
  width: 100%;
  height: calc(100% - 15px);
  background-color: #faefb6; /* Red */
  color: black;
  margin-bottom: 15px;
}

/* The close button */
.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    color: black;
  }
}
</style>
